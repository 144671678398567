import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: 'About';
  desc: Scalars['String'];
  img: Scalars['String'];
};

export type AboutInput = {
  desc: Scalars['String'];
  img: Scalars['String'];
};

export type Allergens = {
  __typename?: 'Allergens';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  property_id: Scalars['String'];
  uploaded_icon?: Maybe<Scalars['String']>;
};

export type AllergensInput = {
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  uploaded_icon?: InputMaybe<Scalars['String']>;
};

export enum AuthMethod {
  Combo = 'COMBO',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Room = 'ROOM'
}

export type Booking = {
  __typename?: 'Booking';
  amount: Scalars['Int'];
  aptmt_date_time: Scalars['String'];
  assigned_to?: Maybe<Array<Maybe<Scalars['String']>>>;
  guest_email?: Maybe<Scalars['String']>;
  guest_name: Scalars['String'];
  guest_phone?: Maybe<Scalars['String']>;
  guest_room_number?: Maybe<Scalars['String']>;
  guest_type?: Maybe<Scalars['String']>;
  hours: Scalars['String'];
  id: Scalars['ID'];
  property_id: Scalars['String'];
  status: Scalars['String'];
  treatments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BookingInput = {
  amount: Scalars['Int'];
  aptmt_date_time: Scalars['String'];
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  guest_email?: InputMaybe<Scalars['String']>;
  guest_name: Scalars['String'];
  guest_phone?: InputMaybe<Scalars['String']>;
  guest_room_number?: InputMaybe<Scalars['String']>;
  guest_type?: InputMaybe<Scalars['String']>;
  hours: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  property_id: Scalars['String'];
  status: Scalars['String'];
  treatments?: InputMaybe<Array<InputMaybe<BookingTreatmentInput>>>;
};

export type BookingTreatmentInput = {
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  duration: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Float'];
};

export type Cart = {
  __typename?: 'Cart';
  dineInItem_id: Scalars['String'];
  dineInItem_name: Scalars['String'];
  dineInItem_price: Scalars['Float'];
  extras?: Maybe<Array<Maybe<ExtraOptions>>>;
  quantity: Scalars['Int'];
  selections?: Maybe<Array<Maybe<Selection>>>;
};

export type CartInput = {
  dineInItem_id: Scalars['String'];
  dineInItem_name: Scalars['String'];
  dineInItem_price: Scalars['Float'];
  extras?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  quantity: Scalars['Int'];
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
};

export type Condiments = {
  condimentId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export enum Days {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Department = {
  __typename?: 'Department';
  department?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export enum Diet_Type {
  NonVegan = 'NonVegan',
  None = 'None',
  Vegan = 'Vegan'
}

export type DineInCategories = {
  __typename?: 'DineInCategories';
  desc?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  is_Activated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  uploaded_icon?: Maybe<Scalars['String']>;
};

export type DineInCategoriesInput = {
  desc?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  uploaded_icon?: InputMaybe<Scalars['String']>;
};

export type DineInItem = {
  __typename?: 'DineInItem';
  allergens?: Maybe<Array<Maybe<Scalars['String']>>>;
  availabletime?: Maybe<Scalars['String']>;
  desc: Scalars['String'];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars['ID'];
  img: Scalars['String'];
  isVegan?: Maybe<Diet_Type>;
  is_Activated?: Maybe<Scalars['Boolean']>;
  is_recommended?: Maybe<Scalars['Boolean']>;
  menu_item_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  property_id: Scalars['String'];
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  selections?: Maybe<Array<Maybe<Selection>>>;
  sequence?: Maybe<Scalars['Int']>;
  spice_level?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DineInItemInput = {
  allergens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  availabletime?: InputMaybe<Scalars['String']>;
  desc: Scalars['String'];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  isVegan?: InputMaybe<Diet_Type>;
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  is_recommended?: InputMaybe<Scalars['Boolean']>;
  menu_item_id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  property_id: Scalars['String'];
  section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
  sequence?: InputMaybe<Scalars['Int']>;
  spice_level?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DineInSection = {
  __typename?: 'DineInSection';
  default_category?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  is_Activated?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
  time_based?: Maybe<Scalars['Boolean']>;
  uploaded_icon?: Maybe<Scalars['String']>;
};

export type DineInSectionInput = {
  default_category?: InputMaybe<Scalars['String']>;
  desc?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['String']>;
  time_based?: InputMaybe<Scalars['Boolean']>;
  uploaded_icon?: InputMaybe<Scalars['String']>;
};

export type Dining = {
  __typename?: 'Dining';
  desc: Scalars['String'];
  id: Scalars['ID'];
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
  service_id?: Maybe<Scalars['Int']>;
};

export type DiningInput = {
  desc: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
  service_id?: InputMaybe<Scalars['Int']>;
};

export type DirectionsAndContact = {
  __typename?: 'DirectionsAndContact';
  address: Scalars['String'];
  email: Scalars['String'];
  img: Scalars['String'];
  phone: Scalars['String'];
};

export type DirectionsAndContactInput = {
  address: Scalars['String'];
  email: Scalars['String'];
  img: Scalars['String'];
  phone: Scalars['String'];
};

export type EMenu = {
  __typename?: 'EMenu';
  auth_method?: Maybe<AuthMethod>;
  department_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interactive?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<DineInItem>>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  room?: Maybe<Scalars['String']>;
};

export type EMenuInput = {
  auth_method?: InputMaybe<AuthMethod>;
  department_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  interactive?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<InputMaybe<DineInItemInput>>>;
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type ExtraOptions = {
  __typename?: 'ExtraOptions';
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type ExtraOptionsInput = {
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FeedbackInput = {
  feedback: Scalars['String'];
  feedback_type_id: Scalars['Int'];
  property_id: Scalars['String'];
  room_id: Scalars['String'];
};

export type Guest = {
  __typename?: 'Guest';
  guest_id?: Maybe<Scalars['String']>;
  guest_name?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
};

export type GuestInput = {
  guest_id?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
};

export type HotelService = {
  __typename?: 'HotelService';
  desc?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  sub_properties?: Maybe<Array<Scalars['String']>>;
  sub_services?: Maybe<Array<Maybe<SubService>>>;
  uploaded_icon?: Maybe<Scalars['String']>;
};

export type HotelServiceInput = {
  desc?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  img?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  sub_properties?: InputMaybe<Array<Scalars['String']>>;
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
  uploaded_icon?: InputMaybe<Scalars['String']>;
};

export type HotlyncServices = {
  __typename?: 'HotlyncServices';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  uploaded_icon?: Maybe<Scalars['String']>;
};

export type HotlyncServicesInput = {
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  uploaded_icon?: InputMaybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  bill_balance?: Maybe<Scalars['String']>;
  bill_items?: Maybe<Array<Maybe<InvoiceItems>>>;
};

export type InvoiceItems = {
  __typename?: 'InvoiceItems';
  description?: Maybe<Scalars['String']>;
  item_amount?: Maybe<Scalars['String']>;
  item_date?: Maybe<Scalars['String']>;
  item_time?: Maybe<Scalars['String']>;
};

export type Is_Activated = {
  __typename?: 'Is_Activated';
  about?: Maybe<Scalars['Boolean']>;
  artNdesign?: Maybe<Scalars['Boolean']>;
  dining?: Maybe<Scalars['Boolean']>;
  directory?: Maybe<Scalars['Boolean']>;
  events?: Maybe<Scalars['Boolean']>;
  hotel_rooms?: Maybe<Scalars['Boolean']>;
  hotel_services?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['Boolean']>;
  neighbourhood?: Maybe<Scalars['Boolean']>;
  rewards?: Maybe<Scalars['Boolean']>;
  safetyNsecurity?: Maybe<Scalars['Boolean']>;
};

export type Is_ActivatedInput = {
  about?: InputMaybe<Scalars['Boolean']>;
  artNdesign?: InputMaybe<Scalars['Boolean']>;
  dining?: InputMaybe<Scalars['Boolean']>;
  directory?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<Scalars['Boolean']>;
  hotel_rooms?: InputMaybe<Scalars['Boolean']>;
  hotel_services?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  neighbourhood?: InputMaybe<Scalars['Boolean']>;
  rewards?: InputMaybe<Scalars['Boolean']>;
  safetyNsecurity?: InputMaybe<Scalars['Boolean']>;
};

export type Itinerary = {
  __typename?: 'Itinerary';
  description: Scalars['String'];
  duration: Scalars['String'];
  end_date: Scalars['String'];
  guest_list?: Maybe<Array<Maybe<Guest>>>;
  id: Scalars['ID'];
  is_for_all?: Maybe<Scalars['Boolean']>;
  is_repeated?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  start_date: Scalars['String'];
  time: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
};

export type ItineraryInput = {
  description: Scalars['String'];
  duration: Scalars['String'];
  end_date: Scalars['String'];
  guest_list?: InputMaybe<Array<InputMaybe<GuestInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  is_for_all?: InputMaybe<Scalars['Boolean']>;
  is_repeated?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  start_date: Scalars['String'];
  time: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  venue?: InputMaybe<Scalars['String']>;
};

export type ItineraryTypes = {
  __typename?: 'ItineraryTypes';
  id: Scalars['String'];
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type ItineraryTypesInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
};

export type Layout = {
  __typename?: 'Layout';
  active?: Maybe<Scalars['Boolean']>;
  cardName: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type LayoutInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cardName: Scalars['String'];
  displayName?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
};

export type MenuItems = {
  condiments?: InputMaybe<Array<InputMaybe<Condiments>>>;
  menuitemid?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  about: About;
  artNdesign?: Maybe<About>;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dining_image?: Maybe<Scalars['String']>;
  dir_con: DirectionsAndContact;
  directory_description?: Maybe<Scalars['String']>;
  events?: Maybe<About>;
  feedback_desc?: Maybe<Scalars['String']>;
  has_order_service_id?: Maybe<Scalars['Boolean']>;
  hotel_name: Scalars['String'];
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  hotel_services_image?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_Activated?: Maybe<Is_Activated>;
  location?: Maybe<About>;
  neighbourhood?: Maybe<About>;
  order_service_id?: Maybe<Scalars['Int']>;
  promotion_desc?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  rewards?: Maybe<About>;
  rooms_image?: Maybe<Scalars['String']>;
  safetyNsecurity?: Maybe<About>;
};

export type MetaDataInput = {
  about: AboutInput;
  artNdesign?: InputMaybe<AboutInput>;
  dining_image?: InputMaybe<Scalars['String']>;
  dir_con: DirectionsAndContactInput;
  directory_description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<AboutInput>;
  feedback_desc?: InputMaybe<Scalars['String']>;
  has_order_service_id?: InputMaybe<Scalars['Boolean']>;
  hotel_name: Scalars['String'];
  hotel_services_image?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  is_Activated?: InputMaybe<Is_ActivatedInput>;
  location?: InputMaybe<AboutInput>;
  neighbourhood?: InputMaybe<AboutInput>;
  order_service_id?: InputMaybe<Scalars['Int']>;
  promotion_desc?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  rewards?: InputMaybe<AboutInput>;
  rooms_image?: InputMaybe<Scalars['String']>;
  safetyNsecurity?: InputMaybe<AboutInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder?: Maybe<Scalars['String']>;
  changeOrderStatus?: Maybe<Scalars['String']>;
  createFeedback?: Maybe<Response>;
  createSimphonyWithComboMeals?: Maybe<StatusResponse>;
  createSimphonyWithInvalidItem?: Maybe<StatusResponse>;
  createSimphonyWithInvalidProperty?: Maybe<StatusResponse>;
  deleteAllergens?: Maybe<Scalars['String']>;
  deleteDineInCategory?: Maybe<Scalars['String']>;
  deleteDineInSection?: Maybe<Scalars['String']>;
  deleteDining?: Maybe<Scalars['String']>;
  deleteEMenu?: Maybe<Scalars['String']>;
  deleteEdible?: Maybe<Scalars['String']>;
  deleteHotelService?: Maybe<Scalars['String']>;
  deleteItinerary?: Maybe<Scalars['String']>;
  deleteItineraryTypes?: Maybe<Scalars['String']>;
  deleteOrder?: Maybe<Scalars['String']>;
  deletePromotion?: Maybe<Scalars['String']>;
  deleteProperty?: Maybe<Property>;
  deleteRole?: Maybe<Scalars['String']>;
  deleteRoom?: Maybe<Scalars['String']>;
  deleteSubProperty?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  requestService?: Maybe<Response>;
  requestServices?: Maybe<Response>;
  syncSimphonyPOSMenuItems?: Maybe<StatusResponse>;
  updateEdibleStatus?: Maybe<DineInItem>;
  updatePromotionStatus?: Maybe<Promotion>;
  upsertAllergens?: Maybe<Allergens>;
  upsertApiKey?: Maybe<Scalars['String']>;
  upsertBooking?: Maybe<Booking>;
  upsertDineInCategory?: Maybe<DineInCategories>;
  upsertDineInSection?: Maybe<DineInSection>;
  upsertDining?: Maybe<Scalars['String']>;
  upsertEMenu?: Maybe<EMenu>;
  upsertEMenuOrder?: Maybe<OrderResponse>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars['String']>;
  upsertHotlyncServices?: Maybe<HotlyncServices>;
  upsertItinerary?: Maybe<Itinerary>;
  upsertItineraryTypes?: Maybe<ItineraryTypes>;
  upsertMetaData?: Maybe<Scalars['String']>;
  upsertOrder?: Maybe<OrderResponse>;
  upsertPromotion?: Maybe<Promotion>;
  upsertProperty?: Maybe<Property>;
  upsertRole?: Maybe<Role>;
  upsertRoom?: Maybe<Room>;
  upsertSubProperty?: Maybe<SubProperty>;
  upsertUser?: Maybe<User>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars['String'];
  orderStatus: Status;
  propertyID: Scalars['String'];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationCreateSimphonyWithComboMealsArgs = {
  propertyID: Scalars['String'];
};

export type MutationCreateSimphonyWithInvalidItemArgs = {
  propertyID: Scalars['String'];
};

export type MutationCreateSimphonyWithInvalidPropertyArgs = {
  propertyID: Scalars['String'];
};

export type MutationDeleteAllergensArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteDineInCategoryArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteDineInSectionArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteDiningArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteEMenuArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteItineraryArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteItineraryTypesArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeletePromotionArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationDeletePropertyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type MutationDeleteRoomArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteSubPropertyArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationRequestServicesArgs = {
  servicesInput?: InputMaybe<Array<ServiceInput>>;
};

export type MutationSyncSimphonyPosMenuItemsArgs = {
  propertyID: Scalars['String'];
};

export type MutationUpdateEdibleStatusArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MutationUpdatePromotionStatusArgs = {
  id: Scalars['ID'];
  propertyID: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MutationUpsertAllergensArgs = {
  allergensInput?: InputMaybe<AllergensInput>;
};

export type MutationUpsertApiKeyArgs = {
  apiKey: Scalars['String'];
  propertyID: Scalars['String'];
};

export type MutationUpsertBookingArgs = {
  bookingInput?: InputMaybe<BookingInput>;
};

export type MutationUpsertDineInCategoryArgs = {
  dineInCategoriesInput?: InputMaybe<DineInCategoriesInput>;
};

export type MutationUpsertDineInSectionArgs = {
  dineInSectionInput?: InputMaybe<DineInSectionInput>;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEMenuArgs = {
  eMenu: EMenuInput;
};

export type MutationUpsertEMenuOrderArgs = {
  id: Scalars['ID'];
  orderInput: OrderInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertHotlyncServicesArgs = {
  hotlyncServicesInput?: InputMaybe<HotlyncServicesInput>;
};

export type MutationUpsertItineraryArgs = {
  itineraryInput?: InputMaybe<ItineraryInput>;
};

export type MutationUpsertItineraryTypesArgs = {
  itineraryTypesInput?: InputMaybe<ItineraryTypesInput>;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPromotionArgs = {
  promotionInput: PromotionInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoleArgs = {
  role: RoleInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationUpsertSubPropertyArgs = {
  subProperty: SubPropertyInput;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars['String'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  cart_items?: Maybe<Array<Cart>>;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  external_guest_id?: Maybe<Scalars['String']>;
  guest_id: Scalars['Int'];
  guest_name: Scalars['String'];
  guest_phone?: Maybe<Scalars['String']>;
  hotel?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  order_id: Scalars['String'];
  property_id: Scalars['String'];
  room_id: Scalars['Int'];
  status?: Maybe<Status>;
  total: Scalars['Float'];
  updated_at?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  external_guest_id?: InputMaybe<Scalars['String']>;
  guest_id: Scalars['Int'];
  guest_name: Scalars['String'];
  guest_phone?: InputMaybe<Scalars['String']>;
  hotel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  room_id: Scalars['Int'];
  status?: InputMaybe<Status>;
  total: Scalars['Float'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
};

export type OtpResponse = {
  __typename?: 'OtpResponse';
  code?: Maybe<Scalars['Int']>;
  departments?: Maybe<Array<Maybe<Department>>>;
  message: Scalars['String'];
};

export type PermissionTypes = {
  __typename?: 'PermissionTypes';
  allergens?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_categories?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_menu?: Maybe<Array<Maybe<Permissions>>>;
  facilities?: Maybe<Array<Maybe<Permissions>>>;
  hotel_info?: Maybe<Array<Maybe<Permissions>>>;
  itinerary?: Maybe<Array<Maybe<Permissions>>>;
  promotions?: Maybe<Array<Maybe<Permissions>>>;
  reports?: Maybe<Array<Maybe<Permissions>>>;
  rooms?: Maybe<Array<Maybe<Permissions>>>;
  services?: Maybe<Array<Maybe<Permissions>>>;
  settings?: Maybe<Array<Maybe<Permissions>>>;
};

export type PermissionTypesInput = {
  allergens?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_categories?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_menu?: InputMaybe<Array<InputMaybe<Permissions>>>;
  facilities?: InputMaybe<Array<InputMaybe<Permissions>>>;
  hotel_info?: InputMaybe<Array<InputMaybe<Permissions>>>;
  itinerary?: InputMaybe<Array<InputMaybe<Permissions>>>;
  promotions?: InputMaybe<Array<InputMaybe<Permissions>>>;
  reports?: InputMaybe<Array<InputMaybe<Permissions>>>;
  rooms?: InputMaybe<Array<InputMaybe<Permissions>>>;
  services?: InputMaybe<Array<InputMaybe<Permissions>>>;
  settings?: InputMaybe<Array<InputMaybe<Permissions>>>;
  user_management?: InputMaybe<Array<InputMaybe<Permissions>>>;
};

export enum Permissions {
  Activate = 'ACTIVATE',
  Add = 'ADD',
  AppSetting = 'APP_SETTING',
  Delete = 'DELETE',
  Edit = 'EDIT',
  HotelInfo = 'HOTEL_INFO',
  PriceEdit = 'PRICE_EDIT',
  Rearrange = 'REARRANGE',
  Sync = 'SYNC'
}

export type Promotion = {
  __typename?: 'Promotion';
  banner_image: Scalars['String'];
  description: Scalars['String'];
  end_date: Scalars['String'];
  id: Scalars['ID'];
  is_Activated?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  start_date: Scalars['String'];
};

export type PromotionInput = {
  banner_image: Scalars['String'];
  description: Scalars['String'];
  end_date: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_Activated?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  start_date: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  background_color?: Maybe<Scalars['String']>;
  background_image?: Maybe<Scalars['String']>;
  button_bg?: Maybe<Scalars['String']>;
  by_dining?: Maybe<Scalars['String']>;
  chat_api_url: Scalars['String'];
  chat_text?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  direct_simphony_pos_check?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  feedback_text?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  has_simphony_pos?: Maybe<Scalars['Boolean']>;
  hide_all_menu?: Maybe<Scalars['Boolean']>;
  hotlync_api_url?: Maybe<Scalars['String']>;
  hotlync_password?: Maybe<Scalars['String']>;
  hotlync_property_id?: Maybe<Scalars['String']>;
  hotlync_username?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  img: Scalars['String'];
  layout?: Maybe<Array<Maybe<Layout>>>;
  list_service?: Maybe<Scalars['Boolean']>;
  logo_tile_bg?: Maybe<Scalars['String']>;
  logo_tile_transperancy?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  navbar_active_background_color?: Maybe<Scalars['String']>;
  navbar_active_title_color?: Maybe<Scalars['String']>;
  navbar_background_color?: Maybe<Scalars['String']>;
  navbar_title_color?: Maybe<Scalars['String']>;
  palm_icon?: Maybe<Scalars['Boolean']>;
  palm_text?: Maybe<Scalars['String']>;
  pos_loc_ref?: Maybe<Scalars['String']>;
  pos_rvc_ref?: Maybe<Scalars['String']>;
  primary_color: Scalars['String'];
  primary_light: Scalars['String'];
  primary_text_color: Scalars['String'];
  restaurant_email?: Maybe<Array<Maybe<Scalars['String']>>>;
  restaurant_link?: Maybe<Scalars['String']>;
  restaurant_phone?: Maybe<Scalars['String']>;
  schedule_request?: Maybe<Scalars['Boolean']>;
  secondary_text_color: Scalars['String'];
  send_to_simphony?: Maybe<Scalars['Boolean']>;
  sender_email?: Maybe<Scalars['String']>;
  show_main_category?: Maybe<Scalars['Boolean']>;
  show_talk_to_us?: Maybe<Scalars['Boolean']>;
  simphony_pos_url?: Maybe<Scalars['String']>;
  spa_id?: Maybe<Scalars['String']>;
  star_rating?: Maybe<Scalars['Boolean']>;
  tile_color?: Maybe<Scalars['String']>;
  title_color?: Maybe<Scalars['String']>;
  transperancy?: Maybe<Scalars['Float']>;
  whatsapp_icon?: Maybe<Scalars['Boolean']>;
};

export type PropertyInput = {
  background_color: Scalars['String'];
  background_image?: InputMaybe<Scalars['String']>;
  button_bg?: InputMaybe<Scalars['String']>;
  by_dining?: InputMaybe<Scalars['String']>;
  chat_api_url: Scalars['String'];
  chat_text?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  direct_simphony_pos_check?: InputMaybe<Scalars['Boolean']>;
  domain: Scalars['String'];
  feedback_text?: InputMaybe<Scalars['String']>;
  font?: InputMaybe<Scalars['String']>;
  has_simphony_pos?: InputMaybe<Scalars['Boolean']>;
  hide_all_menu?: InputMaybe<Scalars['Boolean']>;
  hotlync_api_url: Scalars['String'];
  hotlync_password?: InputMaybe<Scalars['String']>;
  hotlync_property_id?: InputMaybe<Scalars['String']>;
  hotlync_username?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  layout?: InputMaybe<Array<InputMaybe<LayoutInput>>>;
  list_service?: InputMaybe<Scalars['Boolean']>;
  logo_tile_bg?: InputMaybe<Scalars['String']>;
  logo_tile_transperancy?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  navbar_active_background_color?: InputMaybe<Scalars['String']>;
  navbar_active_title_color?: InputMaybe<Scalars['String']>;
  navbar_background_color?: InputMaybe<Scalars['String']>;
  navbar_title_color?: InputMaybe<Scalars['String']>;
  palm_icon?: InputMaybe<Scalars['Boolean']>;
  palm_text?: InputMaybe<Scalars['String']>;
  pos_loc_ref?: InputMaybe<Scalars['String']>;
  pos_rvc_ref?: InputMaybe<Scalars['String']>;
  primary_color: Scalars['String'];
  primary_light: Scalars['String'];
  primary_text_color: Scalars['String'];
  restaurant_email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  restaurant_phone?: InputMaybe<Scalars['String']>;
  schedule_request?: InputMaybe<Scalars['Boolean']>;
  secondary_text_color: Scalars['String'];
  send_to_simphony?: InputMaybe<Scalars['Boolean']>;
  sender_email?: InputMaybe<Scalars['String']>;
  show_main_category?: InputMaybe<Scalars['Boolean']>;
  show_talk_to_us?: InputMaybe<Scalars['Boolean']>;
  simphony_pos_url?: InputMaybe<Scalars['String']>;
  spa_id?: InputMaybe<Scalars['String']>;
  star_rating?: InputMaybe<Scalars['Boolean']>;
  tile_color?: InputMaybe<Scalars['String']>;
  title_color?: InputMaybe<Scalars['String']>;
  transperancy?: InputMaybe<Scalars['Float']>;
  whatsapp_icon?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  getActiveItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getAdminPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getAllDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getAllEMenu?: Maybe<Array<Maybe<EMenu>>>;
  getAllergens?: Maybe<Array<Maybe<Allergens>>>;
  getClosedItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getDepartments?: Maybe<Array<Maybe<Department>>>;
  getDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getEMenu?: Maybe<EMenu>;
  getEMenuGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getFlexiPass?: Maybe<Scalars['String']>;
  getGuest?: Maybe<Response>;
  getGuestDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getGuestEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getGuestList?: Maybe<Array<Maybe<Guest>>>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getGuestPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getHotelService?: Maybe<HotelService>;
  getHotlyncServices?: Maybe<Array<Maybe<HotlyncServices>>>;
  getInvoice?: Maybe<Invoice>;
  getItinerary?: Maybe<Array<Maybe<Itinerary>>>;
  getItineraryTypes?: Maybe<Array<Maybe<ItineraryTypes>>>;
  getMeta?: Maybe<MetaData>;
  getOrders?: Maybe<Array<Maybe<Order>>>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getOtp?: Maybe<Response>;
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getProperty?: Maybe<Property>;
  getPropertyByDomain?: Maybe<Property>;
  getPropertyRoles?: Maybe<Array<Maybe<Role>>>;
  getPropertyTreatments?: Maybe<Array<Maybe<Treatment>>>;
  getPropertyUsers?: Maybe<Array<Maybe<User>>>;
  getQuickServices?: Maybe<QuickServices>;
  getServices?: Maybe<ServiceByDeptmt>;
  getSubProperties?: Maybe<Array<Maybe<SubProperty>>>;
  getTaskHistory?: Maybe<Array<Maybe<TaskHistory>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  simphonyDiscounts?: Maybe<StatusResponse>;
  simphonyServiceCharges?: Maybe<StatusResponse>;
  simphonySignIn?: Maybe<Scalars['String']>;
  verifyOtp?: Maybe<OtpResponse>;
};

export type QueryGetActiveItinerariesArgs = {
  endDate: Scalars['String'];
  propertyID: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryGetAdminPromotionsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetAllDineInSectionsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetAllEMenuArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetAllergensArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetClosedItinerariesArgs = {
  propertyID: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryGetDepartmentsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetDineInCategoriesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetDineInSectionsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetEMenuArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetEMenuGuestOrdersArgs = {
  externalGuestID: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetFlexiPassArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetGuestDineInCategoriesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestEdiblesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestListArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
};

export type QueryGetGuestPromotionsArgs = {
  currDate: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetHotelServiceArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetHotlyncServicesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetInvoiceArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetItineraryArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetItineraryTypesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetOrdersArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  propertyID: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars['String'];
};

export type QueryGetOtpArgs = {
  id: Scalars['String'];
  method: AuthMethod;
  propertyID: Scalars['String'];
};

export type QueryGetPromotionsArgs = {
  currDate: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetPropertyByDomainArgs = {
  domain: Scalars['String'];
};

export type QueryGetPropertyRolesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetPropertyTreatmentsArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetPropertyUsersArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetQuickServicesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetSubPropertiesArgs = {
  propertyID: Scalars['String'];
};

export type QueryGetTaskHistoryArgs = {
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars['String'];
  fileType: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetUserArgs = {
  id: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars['String'];
  propertyID: Scalars['String'];
};

export type QuerySimphonyDiscountsArgs = {
  propertyID: Scalars['String'];
};

export type QuerySimphonyServiceChargesArgs = {
  propertyID: Scalars['String'];
};

export type QuerySimphonySignInArgs = {
  propertyID: Scalars['String'];
};

export type QueryVerifyOtpArgs = {
  otp: Scalars['Int'];
  propertyID: Scalars['String'];
};

export type QuickService = {
  __typename?: 'QuickService';
  department: Scalars['String'];
  id: Scalars['String'];
  qty_by_guest: Scalars['Int'];
  task: Scalars['String'];
};

export type QuickServices = {
  __typename?: 'QuickServices';
  code: Scalars['Int'];
  quicktasklist?: Maybe<Array<Maybe<QuickService>>>;
};

export type Response = {
  __typename?: 'Response';
  code: Scalars['String'];
  guest_arrival?: Maybe<Scalars['String']>;
  guest_departure?: Maybe<Scalars['String']>;
  guest_id?: Maybe<Scalars['Int']>;
  guest_name?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  property_id?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permission?: Maybe<PermissionTypes>;
  property_id: Scalars['String'];
};

export type RoleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  permission?: InputMaybe<PermissionTypesInput>;
  property_id: Scalars['String'];
};

export type Room = {
  __typename?: 'Room';
  desc: Scalars['String'];
  id: Scalars['ID'];
  img: Scalars['String'];
  property_id: Scalars['String'];
  room_type: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

export type RoomInput = {
  desc: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  img: Scalars['String'];
  property_id: Scalars['String'];
  room_type: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type Selection = {
  __typename?: 'Selection';
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  min_limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  selection_items: Array<Maybe<SelectionItem>>;
};

export type SelectionInput = {
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  min_limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  selection_items: Array<InputMaybe<SelectionItemInput>>;
};

export type SelectionItem = {
  __typename?: 'SelectionItem';
  id: Scalars['String'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type SelectionItemInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['Int'];
  task: Scalars['String'];
};

export type ServiceByDeptmt = {
  __typename?: 'ServiceByDeptmt';
  code: Scalars['Int'];
  tasklist?: Maybe<Scalars['AWSJSON']>;
};

export type ServiceInput = {
  menu_items?: InputMaybe<Array<InputMaybe<MenuItems>>>;
  notes?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  quantity: Scalars['Int'];
  room_id: Scalars['String'];
  schedule_task?: InputMaybe<Scalars['String']>;
  task_id: Scalars['Int'];
};

export type SignedUrl = {
  __typename?: 'SignedURL';
  presigned_upload_url?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum Status {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type StatusResponse = {
  __typename?: 'StatusResponse';
  data?: Maybe<Scalars['AWSJSON']>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type SubProperty = {
  __typename?: 'SubProperty';
  id: Scalars['ID'];
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sub_services?: Maybe<Array<Maybe<SubService>>>;
};

export type SubPropertyInput = {
  id?: InputMaybe<Scalars['ID']>;
  img?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  property_id: Scalars['String'];
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
};

export type SubService = {
  __typename?: 'SubService';
  booking_form?: Maybe<Scalars['Boolean']>;
  booking_url?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  img?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  noti_msg?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['Int']>;
  spa_integration?: Maybe<Scalars['Boolean']>;
};

export type SubServiceInput = {
  booking_form?: InputMaybe<Scalars['Boolean']>;
  booking_url?: InputMaybe<Scalars['String']>;
  desc?: InputMaybe<Scalars['String']>;
  img?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  noti_msg?: InputMaybe<Scalars['String']>;
  service_id?: InputMaybe<Scalars['Int']>;
  spa_integration?: InputMaybe<Scalars['Boolean']>;
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  created_time?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  task_list?: Maybe<Scalars['Int']>;
  task_name?: Maybe<Scalars['String']>;
};

export type Treatment = {
  __typename?: 'Treatment';
  category?: Maybe<TreatmentCategory>;
  default_durations?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  property_id: Scalars['String'];
};

export enum TreatmentCategory {
  Massage = 'MASSAGE',
  Other = 'OTHER'
}

export enum Types {
  Alcohol = 'ALCOHOL',
  Breakfast = 'BREAKFAST',
  Desserts = 'DESSERTS',
  Drinks = 'DRINKS',
  HotDrinks = 'HOT_DRINKS',
  Mains = 'MAINS',
  Salads = 'SALADS',
  Soups = 'SOUPS',
  Starters = 'STARTERS'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  is_authorized?: Maybe<Scalars['Boolean']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  property_id: Scalars['String'];
  reports_to?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  shift_end?: Maybe<Scalars['String']>;
  shift_start?: Maybe<Scalars['String']>;
  working_days?: Maybe<Array<Maybe<Days>>>;
};

export type UserInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_authorized?: InputMaybe<Scalars['Boolean']>;
  is_disabled?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  property_id: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  shift_end?: InputMaybe<Scalars['String']>;
  shift_start?: InputMaybe<Scalars['String']>;
  working_days?: InputMaybe<Array<InputMaybe<Days>>>;
};

export type GetAllergensQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetAllergensQuery = {
  __typename?: 'Query';
  getAllergens?: Array<{
    __typename?: 'Allergens';
    name: string;
    id: string;
    icon?: string | null;
    property_id: string;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type GetGuestDineInCategoriesQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetGuestDineInCategoriesQuery = {
  __typename?: 'Query';
  getGuestDineInCategories?: Array<{
    __typename?: 'DineInCategories';
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    sequence?: number | null;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type GetDineInSectionsQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetDineInSectionsQuery = {
  __typename?: 'Query';
  getDineInSections?: Array<{
    __typename?: 'DineInSection';
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    sequence?: number | null;
    default_category?: string | null;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type GetAllDineInSectionsQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetAllDineInSectionsQuery = {
  __typename?: 'Query';
  getAllDineInSections?: Array<{
    __typename?: 'DineInSection';
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    start_time?: string | null;
    end_time?: string | null;
    sequence?: number | null;
    default_category?: string | null;
    time_based?: boolean | null;
    message?: string | null;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type DineInListQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type DineInListQuery = {
  __typename?: 'Query';
  getGuestEdibles?: Array<{
    __typename?: 'DineInItem';
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    menu_item_id?: string | null;
    is_Activated?: boolean | null;
    section?: Array<string | null> | null;
    is_recommended?: boolean | null;
    spice_level?: string | null;
    sequence?: number | null;
    extraOptions?: Array<{
      __typename?: 'ExtraOptions';
      name: string;
      price?: number | null;
    } | null> | null;
    selections?: Array<{
      __typename?: 'Selection';
      id: string;
      name: string;
      min_limit?: number | null;
      limit?: number | null;
      selection_items: Array<{
        __typename?: 'SelectionItem';
        id: string;
        name: string;
        price?: number | null;
      } | null>;
    } | null> | null;
  } | null> | null;
};

export type GetEMenuQueryVariables = Exact<{
  propertyID: Scalars['String'];
  id: Scalars['String'];
}>;

export type GetEMenuQuery = {
  __typename?: 'Query';
  getEMenu?: {
    __typename?: 'EMenu';
    id: string;
    property_id: string;
    name: string;
    interactive?: boolean | null;
    auth_method?: AuthMethod | null;
    items?: Array<{
      __typename?: 'DineInItem';
      id: string;
      name: string;
      type: string;
      img: string;
      desc: string;
      price: number;
      isVegan?: Diet_Type | null;
      availabletime?: string | null;
      allergens?: Array<string | null> | null;
      section?: Array<string | null> | null;
      is_recommended?: boolean | null;
      spice_level?: string | null;
      sequence?: number | null;
      extraOptions?: Array<{
        __typename?: 'ExtraOptions';
        name: string;
        price?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FeedbackListQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type FeedbackListQuery = {
  __typename?: 'Query';
  getFeedbackTypeList?: Array<{
    __typename?: 'Feedback';
    id: number;
    name: string;
  } | null> | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: FeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  __typename?: 'Mutation';
  createFeedback?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type FlexiPassQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type FlexiPassQuery = {
  __typename?: 'Query';
  getFlexiPass?: string | null;
};

export type HotelDetailsQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type HotelDetailsQuery = {
  __typename?: 'Query';
  getMeta?: {
    __typename?: 'MetaData';
    promotion_desc?: string | null;
    feedback_desc?: string | null;
    hotel_services_image?: string | null;
    rooms_image?: string | null;
    directory_description?: string | null;
    dining_image?: string | null;
    order_service_id?: number | null;
    has_order_service_id?: boolean | null;
    about: { __typename?: 'About'; desc: string; img: string };
    artNdesign?: { __typename?: 'About'; desc: string; img: string } | null;
    neighbourhood?: { __typename?: 'About'; desc: string; img: string } | null;
    events?: { __typename?: 'About'; desc: string; img: string } | null;
    safetyNsecurity?: {
      __typename?: 'About';
      desc: string;
      img: string;
    } | null;
    location?: { __typename?: 'About'; desc: string; img: string } | null;
    rewards?: { __typename?: 'About'; desc: string; img: string } | null;
    dining?: Array<{
      __typename?: 'Dining';
      desc: string;
      id: string;
      img: string;
      name: string;
      service_id?: number | null;
    } | null> | null;
    dir_con: {
      __typename?: 'DirectionsAndContact';
      address: string;
      email: string;
      img: string;
      phone: string;
    };
    hotel_rooms?: Array<{
      __typename?: 'Room';
      desc: string;
      id: string;
      img: string;
      room_type: string;
      sequence?: number | null;
    }> | null;
    hotel_services?: Array<{
      __typename?: 'HotelService';
      desc?: string | null;
      id: string;
      img?: string | null;
      name: string;
      icon?: string | null;
      sub_properties?: Array<string> | null;
      sequence?: number | null;
      uploaded_icon?: string | null;
      sub_services?: Array<{
        __typename?: 'SubService';
        desc?: string | null;
        img?: string | null;
        name: string;
        service_id?: number | null;
        booking_url?: string | null;
        booking_form?: boolean | null;
        spa_integration?: boolean | null;
        noti_msg?: string | null;
      } | null> | null;
    }> | null;
    is_Activated?: {
      __typename?: 'Is_Activated';
      about?: boolean | null;
      dining?: boolean | null;
      directory?: boolean | null;
      hotel_rooms?: boolean | null;
      hotel_services?: boolean | null;
      artNdesign?: boolean | null;
      neighbourhood?: boolean | null;
      events?: boolean | null;
      safetyNsecurity?: boolean | null;
      location?: boolean | null;
      rewards?: boolean | null;
    } | null;
  } | null;
};

export type GetItineraryQueryVariables = Exact<{
  propertyID: Scalars['String'];
  guestID: Scalars['Int'];
  roomID: Scalars['String'];
}>;

export type GetItineraryQuery = {
  __typename?: 'Query';
  getItinerary?: Array<{
    __typename?: 'Itinerary';
    id: string;
    property_id: string;
    name?: string | null;
    description: string;
    type?: string | null;
    start_date: string;
    end_date: string;
    time: string;
    duration: string;
    is_repeated?: boolean | null;
    is_for_all?: boolean | null;
    venue?: string | null;
    guest_list?: Array<{
      __typename?: 'Guest';
      room?: string | null;
      guest_id?: string | null;
    } | null> | null;
  } | null> | null;
};

export type CreateOrderMutationVariables = Exact<{
  orderInput: OrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  upsertOrder?: {
    __typename?: 'OrderResponse';
    code: string;
    message?: string | null;
  } | null;
};

export type MyOrdersQueryVariables = Exact<{
  guestID: Scalars['Int'];
  propertyID: Scalars['String'];
}>;

export type MyOrdersQuery = {
  __typename?: 'Query';
  getGuestOrders?: Array<{
    __typename?: 'Order';
    property_id: string;
    id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    guest_id: number;
    guest_name: string;
    created_at: string;
    order_id: string;
    comment?: string | null;
    cart_items?: Array<{
      __typename?: 'Cart';
      dineInItem_id: string;
      quantity: number;
      dineInItem_name: string;
      dineInItem_price: number;
      extras?: Array<{
        __typename?: 'ExtraOptions';
        name: string;
        price?: number | null;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type CreateEMenuOrderMutationVariables = Exact<{
  orderInput: OrderInput;
  id: Scalars['ID'];
}>;

export type CreateEMenuOrderMutation = {
  __typename?: 'Mutation';
  upsertEMenuOrder?: {
    __typename?: 'OrderResponse';
    code: string;
    message?: string | null;
  } | null;
};

export type GuestOrdersQueryVariables = Exact<{
  externalGuestID: Scalars['String'];
  propertyID: Scalars['String'];
}>;

export type GuestOrdersQuery = {
  __typename?: 'Query';
  getEMenuGuestOrders?: Array<{
    __typename?: 'Order';
    property_id: string;
    id: string;
    status?: Status | null;
    total: number;
    external_guest_id?: string | null;
    guest_name: string;
    created_at: string;
    order_id: string;
    comment?: string | null;
    cart_items?: Array<{
      __typename?: 'Cart';
      dineInItem_id: string;
      quantity: number;
      dineInItem_name: string;
      dineInItem_price: number;
      extras?: Array<{
        __typename?: 'ExtraOptions';
        name: string;
        price?: number | null;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type GetOtpQueryVariables = Exact<{
  propertyID: Scalars['String'];
  id: Scalars['String'];
  method: AuthMethod;
}>;

export type GetOtpQuery = {
  __typename?: 'Query';
  getOtp?: { __typename?: 'Response'; code: string; message: string } | null;
};

export type VerifyOtpQueryVariables = Exact<{
  propertyID: Scalars['String'];
  otp: Scalars['Int'];
}>;

export type VerifyOtpQuery = {
  __typename?: 'Query';
  verifyOtp?: {
    __typename?: 'OtpResponse';
    code?: number | null;
    message: string;
  } | null;
};

export type GetGuestPromotionsQueryVariables = Exact<{
  propertyID: Scalars['String'];
  currDate: Scalars['String'];
}>;

export type GetGuestPromotionsQuery = {
  __typename?: 'Query';
  getGuestPromotions?: Array<{
    __typename?: 'Promotion';
    id: string;
    name: string;
    end_date: string;
    start_date: string;
    property_id: string;
    description: string;
    banner_image: string;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type PropertyDetailsQueryVariables = Exact<{
  domain: Scalars['String'];
}>;

export type PropertyDetailsQuery = {
  __typename?: 'Query';
  getPropertyByDomain?: {
    __typename?: 'Property';
    hotlync_api_url?: string | null;
    id: string;
    name: string;
    img: string;
    primary_color: string;
    primary_light: string;
    primary_text_color: string;
    secondary_text_color: string;
    chat_api_url: string;
    currency?: string | null;
    background_color?: string | null;
    font?: string | null;
    tile_color?: string | null;
    by_dining?: string | null;
    show_talk_to_us?: boolean | null;
    show_main_category?: boolean | null;
    has_simphony_pos?: boolean | null;
    simphony_pos_url?: string | null;
    direct_simphony_pos_check?: boolean | null;
    pos_loc_ref?: string | null;
    pos_rvc_ref?: string | null;
    hide_all_menu?: boolean | null;
    palm_text?: string | null;
    palm_icon?: boolean | null;
    restaurant_phone?: string | null;
    spa_id?: string | null;
    whatsapp_icon?: boolean | null;
    title_color?: string | null;
    button_bg?: string | null;
    background_image?: string | null;
    transperancy?: number | null;
    logo_tile_bg?: string | null;
    logo_tile_transperancy?: number | null;
    star_rating?: boolean | null;
    send_to_simphony?: boolean | null;
    navbar_background_color?: string | null;
    navbar_active_background_color?: string | null;
    navbar_title_color?: string | null;
    navbar_active_title_color?: string | null;
    schedule_request?: boolean | null;
    list_service?: boolean | null;
    feedback_text?: string | null;
    chat_text?: string | null;
    layout?: Array<{
      __typename?: 'Layout';
      cardName: string;
      displayName?: string | null;
      sequence: number;
      active?: boolean | null;
    } | null> | null;
  } | null;
};

export type PropertyServicesQueryVariables = Exact<{
  property_id: Scalars['String'];
}>;

export type PropertyServicesQuery = {
  __typename?: 'Query';
  getServices?: {
    __typename?: 'ServiceByDeptmt';
    code: number;
    tasklist?: any | null;
  } | null;
};

export type PropertyServicesMutationMutationVariables = Exact<{
  input: ServiceInput;
}>;

export type PropertyServicesMutationMutation = {
  __typename?: 'Mutation';
  requestService?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type RequestServicesMutationVariables = Exact<{
  input?: InputMaybe<Array<ServiceInput> | ServiceInput>;
}>;

export type RequestServicesMutation = {
  __typename?: 'Mutation';
  requestServices?: {
    __typename?: 'Response';
    code: string;
    message: string;
  } | null;
};

export type GetTaskHistoryQueryVariables = Exact<{
  propertyID: Scalars['String'];
  guestID: Scalars['Int'];
  roomID: Scalars['String'];
}>;

export type GetTaskHistoryQuery = {
  __typename?: 'Query';
  getTaskHistory?: Array<{
    __typename?: 'TaskHistory';
    task_list?: number | null;
    task_name?: string | null;
    status?: string | null;
    quantity?: number | null;
    created_time?: string | null;
  } | null> | null;
};

export type PropertyQuickServicesQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type PropertyQuickServicesQuery = {
  __typename?: 'Query';
  getQuickServices?: {
    __typename?: 'QuickServices';
    code: number;
    quicktasklist?: Array<{
      __typename?: 'QuickService';
      id: string;
      task: string;
      department: string;
      qty_by_guest: number;
    } | null> | null;
  } | null;
};

export type GetHotlyncServicesQueryVariables = Exact<{
  property_id: Scalars['String'];
}>;

export type GetHotlyncServicesQuery = {
  __typename?: 'Query';
  getHotlyncServices?: Array<{
    __typename?: 'HotlyncServices';
    id: string;
    name: string;
    property_id: string;
    uploaded_icon?: string | null;
    icon?: string | null;
    sequence?: number | null;
  } | null> | null;
};

export type GetSubPropertiesQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetSubPropertiesQuery = {
  __typename?: 'Query';
  getSubProperties?: Array<{
    __typename?: 'SubProperty';
    id: string;
    name: string;
    img?: string | null;
    property_id: string;
    sub_services?: Array<{
      __typename?: 'SubService';
      name: string;
      img?: string | null;
      desc?: string | null;
      booking_url?: string | null;
      service_id?: number | null;
      booking_form?: boolean | null;
      spa_integration?: boolean | null;
    } | null> | null;
  } | null> | null;
};

export type GetPropertyTreatmentsQueryVariables = Exact<{
  propertyID: Scalars['String'];
}>;

export type GetPropertyTreatmentsQuery = {
  __typename?: 'Query';
  getPropertyTreatments?: Array<{
    __typename?: 'Treatment';
    id: string;
    category?: TreatmentCategory | null;
    default_durations?: Array<string | null> | null;
    image_url?: string | null;
    name: string;
    price?: number | null;
    property_id: string;
  } | null> | null;
};

export type UpsertBookingMutationVariables = Exact<{
  bookingInput: BookingInput;
}>;

export type UpsertBookingMutation = {
  __typename?: 'Mutation';
  upsertBooking?: { __typename?: 'Booking'; id: string } | null;
};

export type GuestVerificationMutationVariables = Exact<{
  guestName: Scalars['String'];
  propertyID: Scalars['String'];
  roomID: Scalars['String'];
}>;

export type GuestVerificationMutation = {
  __typename?: 'Mutation';
  verifyGuest?: {
    __typename?: 'Response';
    code: string;
    message: string;
    guest_id?: number | null;
    guest_name?: string | null;
    property_id?: string | null;
    room?: string | null;
    guest_departure?: string | null;
    guest_arrival?: string | null;
  } | null;
};

export const GetAllergensDocument = gql`
  query getAllergens($propertyID: String!) {
    getAllergens(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      uploaded_icon
    }
  }
`;

/**
 * __useGetAllergensQuery__
 *
 * To run a query within a React component, call `useGetAllergensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergensQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllergensQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export function useGetAllergensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export type GetAllergensQueryHookResult = ReturnType<
  typeof useGetAllergensQuery
>;
export type GetAllergensLazyQueryHookResult = ReturnType<
  typeof useGetAllergensLazyQuery
>;
export type GetAllergensQueryResult = ApolloReactCommon.QueryResult<
  GetAllergensQuery,
  GetAllergensQueryVariables
>;
export const GetGuestDineInCategoriesDocument = gql`
  query getGuestDineInCategories($propertyID: String!) {
    getGuestDineInCategories(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      sequence
      uploaded_icon
    }
  }
`;

/**
 * __useGetGuestDineInCategoriesQuery__
 *
 * To run a query within a React component, call `useGetGuestDineInCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestDineInCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestDineInCategoriesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetGuestDineInCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGuestDineInCategoriesQuery,
    GetGuestDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGuestDineInCategoriesQuery,
    GetGuestDineInCategoriesQueryVariables
  >(GetGuestDineInCategoriesDocument, options);
}
export function useGetGuestDineInCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGuestDineInCategoriesQuery,
    GetGuestDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGuestDineInCategoriesQuery,
    GetGuestDineInCategoriesQueryVariables
  >(GetGuestDineInCategoriesDocument, options);
}
export type GetGuestDineInCategoriesQueryHookResult = ReturnType<
  typeof useGetGuestDineInCategoriesQuery
>;
export type GetGuestDineInCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetGuestDineInCategoriesLazyQuery
>;
export type GetGuestDineInCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetGuestDineInCategoriesQuery,
  GetGuestDineInCategoriesQueryVariables
>;
export const GetDineInSectionsDocument = gql`
  query getDineInSections($propertyID: String!) {
    getDineInSections(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      sequence
      default_category
      uploaded_icon
    }
  }
`;

/**
 * __useGetDineInSectionsQuery__
 *
 * To run a query within a React component, call `useGetDineInSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInSectionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInSectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export function useGetDineInSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export type GetDineInSectionsQueryHookResult = ReturnType<
  typeof useGetDineInSectionsQuery
>;
export type GetDineInSectionsLazyQueryHookResult = ReturnType<
  typeof useGetDineInSectionsLazyQuery
>;
export type GetDineInSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetDineInSectionsQuery,
  GetDineInSectionsQueryVariables
>;
export const GetAllDineInSectionsDocument = gql`
  query getAllDineInSections($propertyID: String!) {
    getAllDineInSections(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      start_time
      end_time
      sequence
      default_category
      time_based
      message
      uploaded_icon
    }
  }
`;

/**
 * __useGetAllDineInSectionsQuery__
 *
 * To run a query within a React component, call `useGetAllDineInSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDineInSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDineInSectionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllDineInSectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllDineInSectionsQuery,
    GetAllDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllDineInSectionsQuery,
    GetAllDineInSectionsQueryVariables
  >(GetAllDineInSectionsDocument, options);
}
export function useGetAllDineInSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllDineInSectionsQuery,
    GetAllDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllDineInSectionsQuery,
    GetAllDineInSectionsQueryVariables
  >(GetAllDineInSectionsDocument, options);
}
export type GetAllDineInSectionsQueryHookResult = ReturnType<
  typeof useGetAllDineInSectionsQuery
>;
export type GetAllDineInSectionsLazyQueryHookResult = ReturnType<
  typeof useGetAllDineInSectionsLazyQuery
>;
export type GetAllDineInSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetAllDineInSectionsQuery,
  GetAllDineInSectionsQueryVariables
>;
export const DineInListDocument = gql`
  query DineInList($propertyID: String!) {
    getGuestEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      menu_item_id
      extraOptions {
        name
        price
      }
      is_Activated
      section
      is_recommended
      spice_level
      sequence
      selections {
        id
        name
        min_limit
        limit
        selection_items {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useDineInListQuery__
 *
 * To run a query within a React component, call `useDineInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDineInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDineInListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDineInListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DineInListQuery, DineInListQueryVariables>(
    DineInListDocument,
    options
  );
}
export function useDineInListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DineInListQuery,
    DineInListQueryVariables
  >(DineInListDocument, options);
}
export type DineInListQueryHookResult = ReturnType<typeof useDineInListQuery>;
export type DineInListLazyQueryHookResult = ReturnType<
  typeof useDineInListLazyQuery
>;
export type DineInListQueryResult = ApolloReactCommon.QueryResult<
  DineInListQuery,
  DineInListQueryVariables
>;
export const GetEMenuDocument = gql`
  query getEMenu($propertyID: String!, $id: String!) {
    getEMenu(propertyID: $propertyID, id: $id) {
      id
      property_id
      name
      interactive
      auth_method
      items {
        id
        name
        type
        img
        desc
        price
        isVegan
        availabletime
        allergens
        extraOptions {
          name
          price
        }
        section
        is_recommended
        spice_level
        sequence
      }
    }
  }
`;

/**
 * __useGetEMenuQuery__
 *
 * To run a query within a React component, call `useGetEMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEMenuQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEMenuQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEMenuQuery,
    GetEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetEMenuQuery, GetEMenuQueryVariables>(
    GetEMenuDocument,
    options
  );
}
export function useGetEMenuLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEMenuQuery,
    GetEMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetEMenuQuery, GetEMenuQueryVariables>(
    GetEMenuDocument,
    options
  );
}
export type GetEMenuQueryHookResult = ReturnType<typeof useGetEMenuQuery>;
export type GetEMenuLazyQueryHookResult = ReturnType<
  typeof useGetEMenuLazyQuery
>;
export type GetEMenuQueryResult = ApolloReactCommon.QueryResult<
  GetEMenuQuery,
  GetEMenuQueryVariables
>;
export const FeedbackListDocument = gql`
  query FeedbackList($propertyID: String!) {
    getFeedbackTypeList(propertyID: $propertyID) {
      id
      name
    }
  }
`;

/**
 * __useFeedbackListQuery__
 *
 * To run a query within a React component, call `useFeedbackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useFeedbackListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export function useFeedbackListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export type FeedbackListQueryHookResult = ReturnType<
  typeof useFeedbackListQuery
>;
export type FeedbackListLazyQueryHookResult = ReturnType<
  typeof useFeedbackListLazyQuery
>;
export type FeedbackListQueryResult = ApolloReactCommon.QueryResult<
  FeedbackListQuery,
  FeedbackListQueryVariables
>;
export const SubmitFeedbackDocument = gql`
  mutation submitFeedback($input: FeedbackInput!) {
    createFeedback(feedbackInput: $input) {
      code
      message
    }
  }
`;
export type SubmitFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackMutationHookResult = ReturnType<
  typeof useSubmitFeedbackMutation
>;
export type SubmitFeedbackMutationResult =
  ApolloReactCommon.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >;
export const FlexiPassDocument = gql`
  query FlexiPass($propertyID: String!) {
    getFlexiPass(propertyID: $propertyID)
  }
`;

/**
 * __useFlexiPassQuery__
 *
 * To run a query within a React component, call `useFlexiPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlexiPassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlexiPassQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useFlexiPassQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FlexiPassQuery,
    FlexiPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FlexiPassQuery, FlexiPassQueryVariables>(
    FlexiPassDocument,
    options
  );
}
export function useFlexiPassLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FlexiPassQuery,
    FlexiPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FlexiPassQuery, FlexiPassQueryVariables>(
    FlexiPassDocument,
    options
  );
}
export type FlexiPassQueryHookResult = ReturnType<typeof useFlexiPassQuery>;
export type FlexiPassLazyQueryHookResult = ReturnType<
  typeof useFlexiPassLazyQuery
>;
export type FlexiPassQueryResult = ApolloReactCommon.QueryResult<
  FlexiPassQuery,
  FlexiPassQueryVariables
>;
export const HotelDetailsDocument = gql`
  query HotelDetails($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      about {
        desc
        img
      }
      artNdesign {
        desc
        img
      }
      neighbourhood {
        desc
        img
      }
      events {
        desc
        img
      }
      safetyNsecurity {
        desc
        img
      }
      location {
        desc
        img
      }
      rewards {
        desc
        img
      }
      dining {
        desc
        id
        img
        name
        service_id
      }
      dir_con {
        address
        email
        img
        phone
      }
      hotel_rooms {
        desc
        id
        img
        room_type
        sequence
      }
      hotel_services {
        desc
        id
        img
        name
        icon
        sub_services {
          desc
          img
          name
          service_id
          booking_url
          booking_form
          spa_integration
          noti_msg
        }
        sub_properties
        sequence
        uploaded_icon
      }
      promotion_desc
      feedback_desc
      hotel_services_image
      rooms_image
      directory_description
      dining_image
      is_Activated {
        about
        dining
        directory
        hotel_rooms
        hotel_services
        artNdesign
        neighbourhood
        events
        safetyNsecurity
        location
        rewards
      }
      order_service_id
      has_order_service_id
    }
  }
`;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useHotelDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export function useHotelDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export type HotelDetailsQueryHookResult = ReturnType<
  typeof useHotelDetailsQuery
>;
export type HotelDetailsLazyQueryHookResult = ReturnType<
  typeof useHotelDetailsLazyQuery
>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<
  HotelDetailsQuery,
  HotelDetailsQueryVariables
>;
export const GetItineraryDocument = gql`
  query getItinerary($propertyID: String!, $guestID: Int!, $roomID: String!) {
    getItinerary(propertyID: $propertyID, guestID: $guestID, roomID: $roomID) {
      id
      property_id
      name
      description
      type
      start_date
      end_date
      time
      duration
      is_repeated
      is_for_all
      venue
      guest_list {
        room
        guest_id
      }
    }
  }
`;

/**
 * __useGetItineraryQuery__
 *
 * To run a query within a React component, call `useGetItineraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItineraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItineraryQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      guestID: // value for 'guestID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGetItineraryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetItineraryQuery,
    GetItineraryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetItineraryQuery,
    GetItineraryQueryVariables
  >(GetItineraryDocument, options);
}
export function useGetItineraryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetItineraryQuery,
    GetItineraryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetItineraryQuery,
    GetItineraryQueryVariables
  >(GetItineraryDocument, options);
}
export type GetItineraryQueryHookResult = ReturnType<
  typeof useGetItineraryQuery
>;
export type GetItineraryLazyQueryHookResult = ReturnType<
  typeof useGetItineraryLazyQuery
>;
export type GetItineraryQueryResult = ApolloReactCommon.QueryResult<
  GetItineraryQuery,
  GetItineraryQueryVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder($orderInput: OrderInput!) {
    upsertOrder(orderInput: $orderInput) {
      code
      message
    }
  }
`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<
  typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const MyOrdersDocument = gql`
  query MyOrders($guestID: Int!, $propertyID: String!) {
    getGuestOrders(guestID: $guestID, propertyID: $propertyID) {
      cart_items {
        dineInItem_id
        extras {
          name
          price
        }
        quantity
        dineInItem_name
        dineInItem_price
      }
      property_id
      id
      room_id
      status
      total
      guest_id
      guest_name
      created_at
      order_id
      comment
    }
  }
`;

/**
 * __useMyOrdersQuery__
 *
 * To run a query within a React component, call `useMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrdersQuery({
 *   variables: {
 *      guestID: // value for 'guestID'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useMyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export function useMyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export type MyOrdersQueryHookResult = ReturnType<typeof useMyOrdersQuery>;
export type MyOrdersLazyQueryHookResult = ReturnType<
  typeof useMyOrdersLazyQuery
>;
export type MyOrdersQueryResult = ApolloReactCommon.QueryResult<
  MyOrdersQuery,
  MyOrdersQueryVariables
>;
export const CreateEMenuOrderDocument = gql`
  mutation CreateEMenuOrder($orderInput: OrderInput!, $id: ID!) {
    upsertEMenuOrder(orderInput: $orderInput, id: $id) {
      code
      message
    }
  }
`;
export type CreateEMenuOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateEMenuOrderMutation,
  CreateEMenuOrderMutationVariables
>;

/**
 * __useCreateEMenuOrderMutation__
 *
 * To run a mutation, you first call `useCreateEMenuOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEMenuOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEMenuOrderMutation, { data, loading, error }] = useCreateEMenuOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateEMenuOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEMenuOrderMutation,
    CreateEMenuOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEMenuOrderMutation,
    CreateEMenuOrderMutationVariables
  >(CreateEMenuOrderDocument, options);
}
export type CreateEMenuOrderMutationHookResult = ReturnType<
  typeof useCreateEMenuOrderMutation
>;
export type CreateEMenuOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateEMenuOrderMutation>;
export type CreateEMenuOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEMenuOrderMutation,
    CreateEMenuOrderMutationVariables
  >;
export const GuestOrdersDocument = gql`
  query GuestOrders($externalGuestID: String!, $propertyID: String!) {
    getEMenuGuestOrders(
      externalGuestID: $externalGuestID
      propertyID: $propertyID
    ) {
      cart_items {
        dineInItem_id
        extras {
          name
          price
        }
        quantity
        dineInItem_name
        dineInItem_price
      }
      property_id
      id
      status
      total
      external_guest_id
      guest_name
      created_at
      order_id
      comment
    }
  }
`;

/**
 * __useGuestOrdersQuery__
 *
 * To run a query within a React component, call `useGuestOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestOrdersQuery({
 *   variables: {
 *      externalGuestID: // value for 'externalGuestID'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGuestOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GuestOrdersQuery,
    GuestOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GuestOrdersQuery, GuestOrdersQueryVariables>(
    GuestOrdersDocument,
    options
  );
}
export function useGuestOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GuestOrdersQuery,
    GuestOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GuestOrdersQuery,
    GuestOrdersQueryVariables
  >(GuestOrdersDocument, options);
}
export type GuestOrdersQueryHookResult = ReturnType<typeof useGuestOrdersQuery>;
export type GuestOrdersLazyQueryHookResult = ReturnType<
  typeof useGuestOrdersLazyQuery
>;
export type GuestOrdersQueryResult = ApolloReactCommon.QueryResult<
  GuestOrdersQuery,
  GuestOrdersQueryVariables
>;
export const GetOtpDocument = gql`
  query getOtp($propertyID: String!, $id: String!, $method: AuthMethod!) {
    getOtp(propertyID: $propertyID, id: $id, method: $method) {
      code
      message
    }
  }
`;

/**
 * __useGetOtpQuery__
 *
 * To run a query within a React component, call `useGetOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtpQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useGetOtpQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetOtpQuery,
    GetOtpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOtpQuery, GetOtpQueryVariables>(
    GetOtpDocument,
    options
  );
}
export function useGetOtpLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOtpQuery,
    GetOtpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOtpQuery, GetOtpQueryVariables>(
    GetOtpDocument,
    options
  );
}
export type GetOtpQueryHookResult = ReturnType<typeof useGetOtpQuery>;
export type GetOtpLazyQueryHookResult = ReturnType<typeof useGetOtpLazyQuery>;
export type GetOtpQueryResult = ApolloReactCommon.QueryResult<
  GetOtpQuery,
  GetOtpQueryVariables
>;
export const VerifyOtpDocument = gql`
  query verifyOtp($propertyID: String!, $otp: Int!) {
    verifyOtp(propertyID: $propertyID, otp: $otp) {
      code
      message
    }
  }
`;

/**
 * __useVerifyOtpQuery__
 *
 * To run a query within a React component, call `useVerifyOtpQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyOtpQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyOtpQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VerifyOtpQuery,
    VerifyOtpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VerifyOtpQuery, VerifyOtpQueryVariables>(
    VerifyOtpDocument,
    options
  );
}
export function useVerifyOtpLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VerifyOtpQuery,
    VerifyOtpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VerifyOtpQuery, VerifyOtpQueryVariables>(
    VerifyOtpDocument,
    options
  );
}
export type VerifyOtpQueryHookResult = ReturnType<typeof useVerifyOtpQuery>;
export type VerifyOtpLazyQueryHookResult = ReturnType<
  typeof useVerifyOtpLazyQuery
>;
export type VerifyOtpQueryResult = ApolloReactCommon.QueryResult<
  VerifyOtpQuery,
  VerifyOtpQueryVariables
>;
export const GetGuestPromotionsDocument = gql`
  query getGuestPromotions($propertyID: String!, $currDate: String!) {
    getGuestPromotions(propertyID: $propertyID, currDate: $currDate) {
      id
      name
      end_date
      start_date
      property_id
      description
      banner_image
      is_Activated
    }
  }
`;

/**
 * __useGetGuestPromotionsQuery__
 *
 * To run a query within a React component, call `useGetGuestPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestPromotionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      currDate: // value for 'currDate'
 *   },
 * });
 */
export function useGetGuestPromotionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >(GetGuestPromotionsDocument, options);
}
export function useGetGuestPromotionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGuestPromotionsQuery,
    GetGuestPromotionsQueryVariables
  >(GetGuestPromotionsDocument, options);
}
export type GetGuestPromotionsQueryHookResult = ReturnType<
  typeof useGetGuestPromotionsQuery
>;
export type GetGuestPromotionsLazyQueryHookResult = ReturnType<
  typeof useGetGuestPromotionsLazyQuery
>;
export type GetGuestPromotionsQueryResult = ApolloReactCommon.QueryResult<
  GetGuestPromotionsQuery,
  GetGuestPromotionsQueryVariables
>;
export const PropertyDetailsDocument = gql`
  query propertyDetails($domain: String!) {
    getPropertyByDomain(domain: $domain) {
      hotlync_api_url
      id
      name
      img
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
      chat_api_url
      currency
      background_color
      font
      layout {
        cardName
        displayName
        sequence
        active
      }
      tile_color
      by_dining
      show_talk_to_us
      show_main_category
      has_simphony_pos
      simphony_pos_url
      direct_simphony_pos_check
      pos_loc_ref
      pos_rvc_ref
      hide_all_menu
      palm_text
      palm_icon
      restaurant_phone
      spa_id
      whatsapp_icon
      title_color
      button_bg
      background_image
      transperancy
      logo_tile_bg
      logo_tile_transperancy
      star_rating
      send_to_simphony
      navbar_background_color
      navbar_active_background_color
      navbar_title_color
      navbar_active_title_color
      schedule_request
      list_service
      feedback_text
      chat_text
    }
  }
`;

/**
 * __usePropertyDetailsQuery__
 *
 * To run a query within a React component, call `usePropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyDetailsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function usePropertyDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >(PropertyDetailsDocument, options);
}
export function usePropertyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyDetailsQuery,
    PropertyDetailsQueryVariables
  >(PropertyDetailsDocument, options);
}
export type PropertyDetailsQueryHookResult = ReturnType<
  typeof usePropertyDetailsQuery
>;
export type PropertyDetailsLazyQueryHookResult = ReturnType<
  typeof usePropertyDetailsLazyQuery
>;
export type PropertyDetailsQueryResult = ApolloReactCommon.QueryResult<
  PropertyDetailsQuery,
  PropertyDetailsQueryVariables
>;
export const PropertyServicesDocument = gql`
  query propertyServices($property_id: String!) {
    getServices(propertyID: $property_id) {
      code
      tasklist
    }
  }
`;

/**
 * __usePropertyServicesQuery__
 *
 * To run a query within a React component, call `usePropertyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePropertyServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export function usePropertyServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export type PropertyServicesQueryHookResult = ReturnType<
  typeof usePropertyServicesQuery
>;
export type PropertyServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyServicesLazyQuery
>;
export type PropertyServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyServicesQuery,
  PropertyServicesQueryVariables
>;
export const PropertyServicesMutationDocument = gql`
  mutation propertyServicesMutation($input: ServiceInput!) {
    requestService(serviceInput: $input) {
      code
      message
    }
  }
`;
export type PropertyServicesMutationMutationFn =
  ApolloReactCommon.MutationFunction<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;

/**
 * __usePropertyServicesMutationMutation__
 *
 * To run a mutation, you first call `usePropertyServicesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertyServicesMutationMutation, { data, loading, error }] = usePropertyServicesMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePropertyServicesMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >(PropertyServicesMutationDocument, options);
}
export type PropertyServicesMutationMutationHookResult = ReturnType<
  typeof usePropertyServicesMutationMutation
>;
export type PropertyServicesMutationMutationResult =
  ApolloReactCommon.MutationResult<PropertyServicesMutationMutation>;
export type PropertyServicesMutationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;
export const RequestServicesDocument = gql`
  mutation requestServices($input: [ServiceInput!]) {
    requestServices(servicesInput: $input) {
      code
      message
    }
  }
`;
export type RequestServicesMutationFn = ApolloReactCommon.MutationFunction<
  RequestServicesMutation,
  RequestServicesMutationVariables
>;

/**
 * __useRequestServicesMutation__
 *
 * To run a mutation, you first call `useRequestServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestServicesMutation, { data, loading, error }] = useRequestServicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestServicesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >(RequestServicesDocument, options);
}
export type RequestServicesMutationHookResult = ReturnType<
  typeof useRequestServicesMutation
>;
export type RequestServicesMutationResult =
  ApolloReactCommon.MutationResult<RequestServicesMutation>;
export type RequestServicesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RequestServicesMutation,
    RequestServicesMutationVariables
  >;
export const GetTaskHistoryDocument = gql`
  query getTaskHistory($propertyID: String!, $guestID: Int!, $roomID: String!) {
    getTaskHistory(
      propertyID: $propertyID
      guestID: $guestID
      roomID: $roomID
    ) {
      task_list
      task_name
      status
      quantity
      created_time
    }
  }
`;

/**
 * __useGetTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskHistoryQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      guestID: // value for 'guestID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGetTaskHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >(GetTaskHistoryDocument, options);
}
export function useGetTaskHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetTaskHistoryQuery,
    GetTaskHistoryQueryVariables
  >(GetTaskHistoryDocument, options);
}
export type GetTaskHistoryQueryHookResult = ReturnType<
  typeof useGetTaskHistoryQuery
>;
export type GetTaskHistoryLazyQueryHookResult = ReturnType<
  typeof useGetTaskHistoryLazyQuery
>;
export type GetTaskHistoryQueryResult = ApolloReactCommon.QueryResult<
  GetTaskHistoryQuery,
  GetTaskHistoryQueryVariables
>;
export const PropertyQuickServicesDocument = gql`
  query propertyQuickServices($propertyID: String!) {
    getQuickServices(propertyID: $propertyID) {
      code
      quicktasklist {
        id
        task
        department
        qty_by_guest
      }
    }
  }
`;

/**
 * __usePropertyQuickServicesQuery__
 *
 * To run a query within a React component, call `usePropertyQuickServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyQuickServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyQuickServicesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function usePropertyQuickServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >(PropertyQuickServicesDocument, options);
}
export function usePropertyQuickServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyQuickServicesQuery,
    PropertyQuickServicesQueryVariables
  >(PropertyQuickServicesDocument, options);
}
export type PropertyQuickServicesQueryHookResult = ReturnType<
  typeof usePropertyQuickServicesQuery
>;
export type PropertyQuickServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyQuickServicesLazyQuery
>;
export type PropertyQuickServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyQuickServicesQuery,
  PropertyQuickServicesQueryVariables
>;
export const GetHotlyncServicesDocument = gql`
  query getHotlyncServices($property_id: String!) {
    getHotlyncServices(propertyID: $property_id) {
      id
      name
      property_id
      uploaded_icon
      icon
      sequence
    }
  }
`;

/**
 * __useGetHotlyncServicesQuery__
 *
 * To run a query within a React component, call `useGetHotlyncServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotlyncServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotlyncServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function useGetHotlyncServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >(GetHotlyncServicesDocument, options);
}
export function useGetHotlyncServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotlyncServicesQuery,
    GetHotlyncServicesQueryVariables
  >(GetHotlyncServicesDocument, options);
}
export type GetHotlyncServicesQueryHookResult = ReturnType<
  typeof useGetHotlyncServicesQuery
>;
export type GetHotlyncServicesLazyQueryHookResult = ReturnType<
  typeof useGetHotlyncServicesLazyQuery
>;
export type GetHotlyncServicesQueryResult = ApolloReactCommon.QueryResult<
  GetHotlyncServicesQuery,
  GetHotlyncServicesQueryVariables
>;
export const GetSubPropertiesDocument = gql`
  query getSubProperties($propertyID: String!) {
    getSubProperties(propertyID: $propertyID) {
      id
      name
      img
      property_id
      sub_services {
        name
        img
        desc
        booking_url
        service_id
        booking_form
        spa_integration
      }
    }
  }
`;

/**
 * __useGetSubPropertiesQuery__
 *
 * To run a query within a React component, call `useGetSubPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubPropertiesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetSubPropertiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >(GetSubPropertiesDocument, options);
}
export function useGetSubPropertiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSubPropertiesQuery,
    GetSubPropertiesQueryVariables
  >(GetSubPropertiesDocument, options);
}
export type GetSubPropertiesQueryHookResult = ReturnType<
  typeof useGetSubPropertiesQuery
>;
export type GetSubPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetSubPropertiesLazyQuery
>;
export type GetSubPropertiesQueryResult = ApolloReactCommon.QueryResult<
  GetSubPropertiesQuery,
  GetSubPropertiesQueryVariables
>;
export const GetPropertyTreatmentsDocument = gql`
  query getPropertyTreatments($propertyID: String!) {
    getPropertyTreatments(propertyID: $propertyID) {
      id
      category
      default_durations
      image_url
      name
      price
      property_id
    }
  }
`;

/**
 * __useGetPropertyTreatmentsQuery__
 *
 * To run a query within a React component, call `useGetPropertyTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyTreatmentsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyTreatmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyTreatmentsQuery,
    GetPropertyTreatmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyTreatmentsQuery,
    GetPropertyTreatmentsQueryVariables
  >(GetPropertyTreatmentsDocument, options);
}
export function useGetPropertyTreatmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyTreatmentsQuery,
    GetPropertyTreatmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyTreatmentsQuery,
    GetPropertyTreatmentsQueryVariables
  >(GetPropertyTreatmentsDocument, options);
}
export type GetPropertyTreatmentsQueryHookResult = ReturnType<
  typeof useGetPropertyTreatmentsQuery
>;
export type GetPropertyTreatmentsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyTreatmentsLazyQuery
>;
export type GetPropertyTreatmentsQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyTreatmentsQuery,
  GetPropertyTreatmentsQueryVariables
>;
export const UpsertBookingDocument = gql`
  mutation upsertBooking($bookingInput: BookingInput!) {
    upsertBooking(bookingInput: $bookingInput) {
      id
    }
  }
`;
export type UpsertBookingMutationFn = ApolloReactCommon.MutationFunction<
  UpsertBookingMutation,
  UpsertBookingMutationVariables
>;

/**
 * __useUpsertBookingMutation__
 *
 * To run a mutation, you first call `useUpsertBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBookingMutation, { data, loading, error }] = useUpsertBookingMutation({
 *   variables: {
 *      bookingInput: // value for 'bookingInput'
 *   },
 * });
 */
export function useUpsertBookingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertBookingMutation,
    UpsertBookingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpsertBookingMutation,
    UpsertBookingMutationVariables
  >(UpsertBookingDocument, options);
}
export type UpsertBookingMutationHookResult = ReturnType<
  typeof useUpsertBookingMutation
>;
export type UpsertBookingMutationResult =
  ApolloReactCommon.MutationResult<UpsertBookingMutation>;
export type UpsertBookingMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpsertBookingMutation,
    UpsertBookingMutationVariables
  >;
export const GuestVerificationDocument = gql`
  mutation guestVerification(
    $guestName: String!
    $propertyID: String!
    $roomID: String!
  ) {
    verifyGuest(
      guestName: $guestName
      propertyID: $propertyID
      roomID: $roomID
    ) {
      code
      message
      guest_id
      guest_name
      property_id
      room
      guest_departure
      guest_arrival
    }
  }
`;
export type GuestVerificationMutationFn = ApolloReactCommon.MutationFunction<
  GuestVerificationMutation,
  GuestVerificationMutationVariables
>;

/**
 * __useGuestVerificationMutation__
 *
 * To run a mutation, you first call `useGuestVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestVerificationMutation, { data, loading, error }] = useGuestVerificationMutation({
 *   variables: {
 *      guestName: // value for 'guestName'
 *      propertyID: // value for 'propertyID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGuestVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >(GuestVerificationDocument, options);
}
export type GuestVerificationMutationHookResult = ReturnType<
  typeof useGuestVerificationMutation
>;
export type GuestVerificationMutationResult =
  ApolloReactCommon.MutationResult<GuestVerificationMutation>;
export type GuestVerificationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >;
